import React, { useEffect, useMemo, useState } from "react";
import DetailWrapper from "../../components/detailWrapper";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { useTranslation } from "react-i18next";
import { Box, Divider, Flex } from "@chakra-ui/react";
import ButtonTab from "../../components/TabComponent/ButtonTab";
import { useSearchParams } from "react-router-dom";
import ReceiveRewardsList from "./components/receiveRewardsList";

const SimpleReceiveRewards = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`receive-reward.${key}`);

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") || "";

  const [selected, setSelected] = useState("");

  const TAB = [
    { label: i18n("unUse"), value: "unused" },
    { label: i18n("used"), value: "history" },
    { label: i18n("expired"), value: "expired" },
  ];

  const tabComponents = useMemo(() => {
    switch (selected) {
      case "unused":
        return <ReceiveRewardsList selected={selected} tab={selected} />;
      case "history":
        return <ReceiveRewardsList selected={selected} tab={selected} />;
      case "expired":
        return <ReceiveRewardsList selected={selected} tab={selected} />;
      default:
        return <></>;
    }
  }, [selected]);

  useEffect(() => {
    setSelected(tab || "unused");
  }, [tab]);

  return (
    <>
      <DetailWrapper>
        <CloseDetailPage title={i18n("myReceiveReward")} />
        <Box>
          <Box as="section" py="common-y">
            <Flex as="section" alignItems={`center`} mb="10px">
              <ButtonTab
                setTab={setSelected}
                TABS={TAB}
                tab={tab || "unused"}
              />
            </Flex>
            <Divider my="10px" />
            <Box
              mb="10px"
              display="flex"
              alignItems={`center`}
              justifyContent={`space-between`}
              gap="15px"
              as="section"
            >
              <Flex w="100%" as="section" direction={`column`} gap="10px">
                {tabComponents}
              </Flex>
            </Box>
          </Box>
        </Box>
      </DetailWrapper>
    </>
  );
};

export default SimpleReceiveRewards;
