import { Box, Button, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BsLine } from "react-icons/bs";
import { waitTime } from "../../../utils/waitTime";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { lineLogin } from "../../../api/postApi";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

const LineLogin = ({ setButtonLoading, buttonLoading, apiTrigger = false }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const line_code = searchParams.get("code");

  const dispatch = useDispatch();

  const invite = Cookies.get("invite");
  const state = searchParams.get("state");

  const navigate = useNavigate();

  useEffect(() => {
    if (!line_code) return;

    setButtonLoading(true);

    const loginParams = { code: line_code };

    if (state) {
      loginParams.invite = state;
    }

    lineLogin(loginParams)
      .then((res) => {
        // 這裡就是你原本的邏輯
        if (Cookies.get("token")) {
          Cookies.remove("token");
        }
        Cookies.set("token", res.token, { expires: 3 });
        dispatch({ type: "INITIALIZE_API", navigate: navigate });
        navigate("/");
      })
      .finally(() => {
        setButtonLoading(false);
      });
  }, [line_code, state]);

  return (
    <Button
      as="a"
      isLoading={buttonLoading}
      w={`100%`}
      display={`flex`}
      alignItems={`center`}
      justifyContent={"space-between"}
      fontWeight={`medium`}
      bg={`#49C755`}
      py="20px"
      pr="30px"
      type="button"
      id="customBtn"
      color={`white`}
      className=""
      _hover={{
        bg: "#49C755",
      }}
      href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&state=${
        invite || ""
      }&redirect_uri=${process.env.REACT_APP_API_URL}/tw/signin&client_id=${
        process.env.REACT_APP_LINE_CLIENT_ID
      }&scope=profile&nonce=`}
    >
      <BsLine className="text-xl" />
      <Text my="0">Log in</Text>
    </Button>
  );
};

export default LineLogin;
