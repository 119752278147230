import React, { useEffect, useMemo, useState } from "react";
import DetailWrapper from "../../../components/detailWrapper";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router";
import OriginInputCustom from "../../../components/InputComponents/originInputCustom";
import {
  transactionExpress,
  transactionExpressForInput,
} from "../../../regex_constanst";
import { useDispatch, useSelector } from "react-redux";
import { notice } from "../../../redux/action/action";
import { MD5 } from "crypto-js";
import { useTranslation } from "react-i18next";
import { bindCardInformation } from "../../../api/postApi";
import { customCryptoPassword } from "../../../utils/customCryptoPassword";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Divider,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiLockPasswordFill } from "react-icons/ri";
import CustomInput from "../../../components/InputComponents/customInput";
import { FaIdCard } from "react-icons/fa";

const iconStyle = "text-gray-300";

const SetTransactionPassword = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_formHint = (key, props) => t(`formHint.${key}`, { ...props });

  const memberData = useSelector((state) => state.isMemberInfo);
  const currentCurrency = useSelector((state) => state.isWalletCurrency);

  const navigate = useNavigate();

  const textColor = useColorModeValue("dark.100", "light.100");
  const noteBg = useColorModeValue("light.100", "dark.100");

  const dispatch = useDispatch();

  const [data, setData] = useState({
    secret_pwd: "",
    secret_pwd_again: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const [uploadIdCard, setUploadIdCard] = useState(null);

  const { secret_pwd, secret_pwd_again } = data;

  const handleInputTransactionPassword = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    localStorage.setItem(name, customCryptoPassword(value).toString());
  };

  useEffect(() => {
    if (memberData.isBindAccount == 1) {
      navigate("../bind-id-card");
      return;
    }
    if (
      localStorage.getItem("step") === null &&
      memberData.isBindAccount == 0
    ) {
      navigate("../bind-id-card");
    }
  }, [memberData]);

  const uploadParamsList = [
    "bank_name",
    "bank_account_name",
    // "bank_branch_name",
    "bank_account",
    // "bank_card_number",
    "id_card",
    // "secret_pwd",
  ];

  const currencyCode = localStorage.getItem("currency_code");
  const bankAccount = useMemo(() => {
    return memberData.bankAccount?.[currencyCode];
  }, [memberData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const { secret_pwd, secret_pwd_again } = data;
    // const isWithdrawPasswordSet = memberData.withdraw_password_set == 1;

    let bodyFormData = new FormData();

    for (let i = 0; i < uploadParamsList.length; i++) {
      bodyFormData.append(
        uploadParamsList[i],
        localStorage.getItem(uploadParamsList[i])
      );
    }
    // 從localStorage中取currencyCode的值，並把他加到 bodyFormData 裡面
    const currencyCode =
      localStorage.getItem("currency_code") || currentCurrency;
    bodyFormData.append("currency_code", currencyCode);

    if (bankAccount?.id_pic != 1) {
      bodyFormData.append("id_pic", uploadIdCard);
    }
    bodyFormData.append(
      "birth",
      dayjs(localStorage.getItem("birth")).format("YYYY-MM-DD")
    );

    bindCardInformation({
      formData: bodyFormData,
    })
      .then((data) => {
        dispatch({ type: "GET_MEMBER_DATA" });
        dispatch(
          notice({ title: i18n_formHint("setSuccess"), type: "success" })
        );
        localStorage.clear();
        navigate("../bind-success");
      })
      .catch((err) => {})
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const formData = [
    {
      name: "secret_pwd",
      label: "交易密碼",
      type: "password",
      placeholder: i18n("idCard.setTransactionPasswordHint"),
      pattern: transactionExpressForInput,
      value: secret_pwd,
      title: i18n("idCard.setTransactionPasswordHint"),
      onChange: (e) => {
        handleInputTransactionPassword(e);
      },
      icon: <RiLockPasswordFill className={iconStyle} />,
    },
    {
      name: "secret_pwd_again",
      label: "再次輸入交易密碼",
      type: "password",
      placeholder: i18n("idCard.setTransactionPasswordHint"),
      pattern: transactionExpressForInput,
      value: secret_pwd_again,
      onChange: (e) => {
        handleInputTransactionPassword(e);
      },
      icon: <RiLockPasswordFill className={iconStyle} />,
    },
  ];

  return (
    <DetailWrapper
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br`}
    >
      <CloseDetailPage
        hasPrev={
          <HiChevronLeft
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
        }
        title={`${i18n("bindIdCard")}`}
      />
      <Box
        as="article"
        display="flex"
        flexDirection={`column`}
        gap="15px"
        p="15px"
        fontWeight={`medium`}
        color={textColor}
      >
        {/* {memberData.withdraw_password_set == 0 && (
          <Text as="span" color="brand.500" fontSize="md">
            {i18n("idCard.setTransactionPassword")}
          </Text>
        )} */}
        <form onSubmit={(e) => handleSubmit(e)}>
          {/* {memberData.withdraw_password_set == 0 &&
            formData.map((item, index) => {
              return (
                <Box mb="10px">
                  <CustomInput
                    props={{
                      ...item,
                      label: i18n(`form.${item.name}`),
                    }}
                  />
                </Box>
              );
            })} */}
          <CustomInput
            props={{
              onChange: (e) => {
                const selectedFile = e.target.files[0];
                setUploadIdCard(selectedFile);
              },
              label: i18n("form.uploadIdCard"),
              required: true,
              type: "file",
              name: "upload",
              icon: <FaIdCard className={iconStyle} />,
            }}
          />
          <Divider my="10px" />
          <Button
            type="submit"
            variant={`brandPrimary`}
            w="100%"
            isLoading={buttonLoading}
            disabled={
              // (memberData.withdraw_password_set !== 1 &&
              //   !Object.keys(data).every((item) => data?.[item] !== "")) ||
              buttonLoading
            }
            size="md"
          >
            {i18n_common("updateData")}
          </Button>
        </form>
      </Box>
    </DetailWrapper>
  );
};

export default SetTransactionPassword;
