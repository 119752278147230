import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useNumberInput,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { FaFaceSadTear } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/formatNumber";
import useCurrencySymbol from "../../../hook/useCurrencySymbol";
import useMyBalance from "../../../hook/useMyBalance";
import { buyRouletteTimes } from "../../../api/postApi";
import { useDispatch } from "react-redux";
import { notice } from "../../../redux/action/action";

const BuyRoulettesTimesModal = ({
  rouletteDetail,
  copy_writing,
  isOpen,
  onClose,
  setTrigger,
}) => {
  const { t } = useTranslation();
  const i18n_roulette = (key, props) => t(`roulette.${key}`, { ...props });

  const dispatch = useDispatch();

  const can_buy_times = useMemo(() => {
    return rouletteDetail?.remaining_times || 0;
  }, [rouletteDetail]);
  const buy_price = useMemo(() => {
    return rouletteDetail?.price || 0;
  }, [rouletteDetail]);

  const current_symbol = useCurrencySymbol();

  const balance = useMyBalance();

  const [times, setTimes] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 0,
      min: 0,
      max: can_buy_times,
      precision: 0,
      type: "number",
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const total_buy_price = useMemo(() => {
    return times * buy_price;
  }, [times, buy_price]);

  const balance_not_enough = useMemo(() => {
    return balance - total_buy_price < 0;
  }, [balance, total_buy_price]);

  const handleBuyTimes = async () => {
    await setButtonLoading(true);
    await buyRouletteTimes({
      roulette_uid: rouletteDetail?.roulettes_uid,
      times: Number(times),
    })
      .then(() => {
        dispatch(
          notice({
            content: i18n_roulette("buy_success"),
            type: "success",
          })
        );
        dispatch({ type: "GET_BALANCE" });
        dispatch({ type: "GET_MEMBER_DATA" });
        setTrigger((prev) => !prev);
        setButtonLoading(false);
        onClose();
        setTimes(0);
      })
      .catch((err) => {
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    setTimes(input?.value);
  }, [input?.value]);

  return (
    <>
      <Modal isCentered size={"xs"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          py="20px"
          borderBottom={"7px solid"}
          borderColor="#95690E"
          bgGradient={"linear(to-b,#FCC85A,#C28A17)"}
          borderRadius={"15px"}
          position="relative"
        >
          <Box
            zIndex={1}
            position="absolute"
            top="-10px"
            right="-10px"
            onClick={onClose}
          >
            <Box
              w="46px"
              h="46px"
              borderRadius={"full"}
              bgGradient={"linear(to-r,brand.500,brand.900)"}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              cursor={"pointer"}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                bgGradient={"linear(to-r,red.600,red.700)"}
                borderRadius={"full"}
                border="1px solid"
                borderColor="leaderBoard.v3-border"
              >
                <Icon as={IoClose} color="light.100" fontSize="32px" />
              </Box>
            </Box>
          </Box>
          <ModalBody>
            <Text fontWeight={600} fontSize="lg">
              {i18n_roulette("buy_name_roulette_times", {
                name: copy_writing?.sub_title,
              })}
            </Text>
            <Divider my="10px" />
            <VStack align={"flex-start"} spacing={"10px"}>
              <VStack fontSize="xs" align={"flex-start"} spacing={"5px"}>
                <Text fontWeight={600}>
                  {i18n_roulette("single_price")}：{formatNumber(buy_price)}
                  {current_symbol}
                </Text>
                <Text fontWeight={600}>
                  {i18n_roulette("remaining_buy_times")}：{can_buy_times}
                </Text>
              </VStack>
              <HStack maxW="320px">
                <Button {...inc}>+</Button>
                <Input {...input} value={times} />
                <Button {...dec}>-</Button>
              </HStack>
              <VStack
                color={balance_not_enough ? "red.500" : "special.1"}
                fontSize="xs"
                align={"flex-start"}
                spacing={"5px"}
              >
                <Text fontWeight={600}>
                  *{i18n_roulette("total_buy_amount")}：
                  {formatNumber(total_buy_price)}
                  {current_symbol}
                </Text>
                <Text fontWeight={600}>
                  *{i18n_roulette("remaining_balance")}：
                  {balance_not_enough
                    ? i18n_roulette("balance_not_enough")
                    : formatNumber(balance - total_buy_price)}
                </Text>
              </VStack>
              <Button
                isDisabled={times == 0 || balance_not_enough}
                isLoading={buttonLoading}
                mt="10px"
                variant={"brandPrimary"}
                type="button"
                w="100%"
                onClick={handleBuyTimes}
              >
                {i18n_roulette("buy_times_roulette", { times: times })}
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BuyRoulettesTimesModal;
