import React, { useMemo } from "react";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import GetParams from "../../utils/getParams";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../replace_str_constant";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/formatNumber";
import CopyIcon from "../../components/CopyComponent/CopyIcon";

const DepositIframe = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`deposit.${key}`, { ...props });

  const depositLink = useSelector((state) => state.isDepositLink);

  const bank_payment = localStorage.getItem("bank_payment");
  const pay_amount = localStorage.getItem("pay_amount");

  const process_bank_payment = useMemo(() => {
    if (bank_payment) {
      return JSON.parse(bank_payment);
    }
    return {};
  }, [bank_payment]);
  console.log(process_bank_payment);
  const method = GetParams("method");
  return (
    <Box
      position={"fixed"}
      w="100%"
      h="100%"
      top="0px"
      left="0px"
      zIndex={"9999999"}
      bg="dark.200"
    >
      <CloseDetailPage
        className={`bg-base-white dark:bg-base-dark !mb-0`}
        title={capitalizeFirstLetter(method)}
        hasPrev={<Box w="20px"></Box>}
      />
      {Object.keys(process_bank_payment)?.length > 0 && (
        <Box p="10px">
          <Text textAlign={"center"} fontSize="2xl">
            {i18n("payment_information")}
          </Text>
          <Divider my="10px" />
          <Box bg="dark.100" p="10px" borderRadius={"10px"}>
            <Flex direction={"column"} gap="10px">
              {Object.keys(process_bank_payment)?.map((bank) => {
                return (
                  <Flex>
                    <Text>{i18n(bank)}：</Text>
                    <Flex gap="5px">
                      <Text>{process_bank_payment[bank]}</Text>
                      <CopyIcon copyLink={process_bank_payment[bank]} />
                    </Flex>
                  </Flex>
                );
              })}
              <Flex>
                <Text>{i18n("pay_amount")}：</Text>
                <Text>{formatNumber(pay_amount)}</Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      )}
      <iframe
        src={depositLink}
        className="h-[calc(100vh-60px)] w-full"
      ></iframe>
    </Box>
  );
};

export default DepositIframe;
