import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { COMMON_WEB_PATH, COMMON_WEB_PATH_JP } from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { languageTransfer } from "../../../utils/languageTransfer";
import i18next from "i18next";
import {
  Divider,
  Flex,
  Image,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import BonusWalletDetail from "./bonusWalletDetail";

const NowGame = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`gamebox.${key}`, { ...props });
  const i18n_common = (key) => t(`common.${key}`);

  const gameConfig = useSelector((state) => state.isGameConfig);
  const [loaded, setLoaded] = useState(false);
  const titleText = useColorModeValue("dark.100", "light.100");
  const cardBg = useColorModeValue("light.300", "dark.100");

  const { game_platform, gameCode, game_subtype, SubPlatformEnum } = gameConfig;

  const gameName = useMemo(() => {
    return gameConfig?.[
      `gameName${languageTransfer({ lang: i18next.language })}`
    ];
  }, [gameConfig, i18next.language]);

  return (
    <>
      <Flex direction={`column`} gap="10px">
        <Flex
          direction={`column`}
          gap="3px"
          alignItems={`flex-start`}
          justifyContent={`space-between`}
        >
          <Text as="span" fontWeight={`medium`} fontSize="lg" color={titleText}>
            {i18n("gamePlatformNow")}
          </Text>
        </Flex>
        <Divider className="!my-0" />

        <Flex
          gap="5px"
          direction={`column`}
          borderRadius={`common`}
          p="8px"
          bg={cardBg}
        >
          <Flex gap="10px">
            <Skeleton isLoaded={loaded} variant={`brandPrimary`}>
              <Image
                onLoad={() => {
                  setLoaded(true);
                }}
                src={`${COMMON_WEB_PATH_JP}/game-icon-ds88/en/${game_platform}/${gameCode}.webp`}
                className={`w-[65px] h-[65px] rounded-[10px] mr-[15px]`}
              />
            </Skeleton>
            <Flex direction={`column`} textColor={titleText}>
              <Text fontSize="xl" fontWeight={`bold`} mb="5px">
                {gameName}
              </Text>
              <Text
                as="h5"
                fontSize="lg"
                color="special.1"
                mb="0px"
                fontWeight={`medium`}
              >
                {SubPlatformEnum || game_platform} - {i18n_common(game_subtype)}
              </Text>
            </Flex>
          </Flex>
          <BonusWalletDetail />
        </Flex>
      </Flex>
    </>
  );
};

export default NowGame;
