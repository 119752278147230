import React from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter as Router, HashRouter, Route } from "react-router-dom";
import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  useColorModeValue,
} from "@chakra-ui/react";
import store from "./redux/store";
import { skeletonTheme } from "./chakraTheme/components/skeleton";
import { buttonTheme } from "./chakraTheme/components/button";
import { spinnerTheme } from "./chakraTheme/components/spinner";
import { inputTheme } from "./chakraTheme/components/input";
import { selectTheme } from "./chakraTheme/components/select";
import { checkboxTheme } from "./chakraTheme/components/checkbox";
import { numberInputTheme } from "./chakraTheme/components/inputNumber";
import { progressTheme } from "./chakraTheme/components/progress";
import { modalTheme } from "./chakraTheme/components/modal";
import Cookies from "js-cookie";
import i18next from "i18next";
import {
  ReduxRouter,
  reduxRouterSelector,
} from "@lagunovsky/redux-react-router";
import history from "./redux/history";
import { popoverTheme } from "./chakraTheme/components/popover";
import { colorConfig } from "./chakraTheme/colorScheme";
import { mode } from "@chakra-ui/theme-tools";
import { lngsList } from "./i18n/config";
import { WEB_LAYOUT } from "./constant";

import { textareaTheme } from "./chakraTheme/components/textarea";
// import "./wdyr";
//這是用來檢查哪些地方有重複渲染的套件

const root = ReactDOM.createRoot(document.getElementById("root"));
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .catch((registrationError) => {
        console.error("ServiceWorker registration failed: ", registrationError);
      });
  });
}
const pathLang = window.location.pathname.split("/")[1];
if (!lngsList.includes(pathLang)) {
  if (localStorage.getItem("i18nextLng")) {
    localStorage.setItem("i18nextLng", localStorage.getItem("i18nextLng"));
  } else {
    localStorage.setItem("i18nextLng", process.env.REACT_APP_DEFAULT_LANGUAGE);
  }
} else if (lngsList.includes(pathLang)) {
  localStorage.setItem("i18nextLng", pathLang);
} else {
  localStorage.setItem("i18nextLng", process.env.REACT_APP_DEFAULT_LANGUAGE);
}

function onRender(
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
) {
  // Aggregate or log render timings...
  console.log({
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime,
    interactions,
  });
}

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bgGradient:
          props.colorMode === "light"
            ? "light.100"
            : `linear(to-bl,#c35a01,#943c07,#5d1400)`,
        // bgImage: `url(${BG_MASK_C})`,
        bgSize: "100% 500%",
        // bgRepeat: "no-repeat,no-repeat",
        bg: "unset",
      },
    }),
  },
  colors: {
    gamebox: {
      base: "#21262A",
    },
    "header-icon": {
      1: "#dd5e01",
    },
    footer: {
      light: "#7f7f7f",
      dark: "#0a0d0f",
    },
    light: {
      100: "#FFFFFF",
      200: "#f1f2f4",
      300: "#DDE0E3", //deep-gray
      350: "rgb(170,170,170)",
      400: "#535353",
      500: "#15191C", //input-gray
      600: "#5f5f5f",
    },
    dark: {
      100: "#21262A",
      200: "#0a0d0f",
      300: "#5b5b5b", //dark-gray-text
      400: "#32383E", //deep-dark
      500: "#4C555E",
      600: "#2b2b2b",
    },
    "menu-box": {
      light_hover: "#e0e1e2",
      dark_hover: "#21262A",
    },
    ...colorConfig[window.web_style || "happy_paradise-main"],
  },
  sizes: {
    10: "3rem",
  },
  radii: {
    common: WEB_LAYOUT === "v3" ? "12px" : "12px",
  },
  space: {
    "common-x": WEB_LAYOUT === "v3" ? "10px" : "12px",
    "common-y": WEB_LAYOUT === "v3" ? "6px" : "8px",
    "home-space": "5px",
  },
  config: {
    // initialColorMode: Cookies.get("color-mode") || "dark",
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  components: {
    Skeleton: skeletonTheme,
    Button: buttonTheme,
    Spinner: spinnerTheme,
    Input: inputTheme,
    Select: selectTheme,
    Checkbox: checkboxTheme,
    NumberInput: numberInputTheme,
    Progress: progressTheme,
    Modal: modalTheme,
    Popover: popoverTheme,
    Textarea: textareaTheme,
  },
  zIndices: {
    modal: 999999,
    tooltip: 999999,
  },
});

const routerSelector = (state) => state.navigator;
root.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      {/* <Router basename={"" + i18next.language === "tw" ? "" : i18next.language}> */}
      <ReduxRouter
        // basename={`/${
        //   localStorage.getItem("i18nextLng") === "tw"
        //     ? ""
        //     : localStorage.getItem("i18nextLng")
        // }`}
        basename={`/${localStorage.getItem("i18nextLng")}`}
        history={history}
        routerSelector={routerSelector}
      >
        <ColorModeScript initialColorMode={"dark"} />
        <App onRender={onRender} />
      </ReduxRouter>
      {/* </Router> */}
    </Provider>
  </ChakraProvider>
);

if (!window.location.pathname.includes(localStorage.getItem("i18nextLng"))) {
  const currentPathname = window.location.pathname;
  window.location.pathname =
    localStorage.getItem("i18nextLng") + currentPathname;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
