import dayjs from "dayjs";
import {
  api,
  eventApi,
  giftApi,
  recordApi,
  repeatApi,
  transferApi,
  userApi,
} from "./baseApi";
import { formatTime } from "../utils/getDay";
import store from "../redux/store";

export const getCheckInEvent = ({ paramsData } = {}) => {
  return api.get("/continuous/event/list", { params: { paramsData } });
};

export const receiveCheckInEvent = ({ checkInUid, attributeUid } = {}) => {
  return api.get(
    `/continuous/event/receiveReward/${checkInUid}/${attributeUid}`
  );
};

export const getUserInfo = () => {
  return userApi.get("/getMemberInfo");
};

export const getRestrictWagering = () => {
  return userApi.get("/checkRestrictedWagering");
};

export const getVerifyCode = () => {
  return api.get("/verifyImg");
};

export const getCryptoRate = () => {
  return api.get("/getRate");
};

export const getDepositPaymentList = () => {
  return repeatApi.get("/payment/list");
};

export const getUserGameList = () => {
  return userApi.get("/getGameList");
};

export const getTransferWallet = ({
  platformUid,
  platformType,
  realPoint,
} = {}) => {
  return transferApi.get("/getTransferBalance", {
    params: {
      platformUid: platformUid,
      platformType: platformType,
      realPoint: realPoint || 0,
    },
  });
};

export const getGiftList = () => {
  return giftApi.get("/list");
};

// 函式名稱：getTransferWallet，它是一個匯出（export）的函式，可以在其他模塊中使用。
// 函式參數：這個函式接受一個包含 `platformUid` 和 `platformType` 的物件作為參數。
// 如果沒有提供這些參數，則預設為一個空物件（{}）。
// 函式主體：這個函式返回一個 API 請求，使用了 `userApi.get` 方法，請求的路徑為 "/getTransferBalance"。
// 在這個請求中，我們使用 `params` 屬性傳遞一個物件，包含 `platformUid` 和 `platformType` 的值。

export const getTransactionRecord = ({ action, params } = {}) => {
  const { str_date, end_date } = params;

  return recordApi.get(`/transaction/${action}`, {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD HH:mm:ss")}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD HH:mm:ss")}`,
    },
  });
};

export const getBetRecord = ({ params }) => {
  const { str_date, end_date } = params;

  return api.get("/report/netprofit", {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD HH:mm:ss")}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD HH:mm:ss")}`,
    },
  });
};

// export const getGiftList = ({ action, params } = {}) => {
//   const { str_date, end_date, time_type } = params;
//   return giftApi.get(`/gift/${action}`, {
//     params: {
//       str_date: `${formatTime(str_date)}`,
//       end_date: `${formatTime(end_date)}`,
//       time_type: time_type,
//     },
//   });
// };

export const getCommissionList = () => {
  return api.get("/commission");
}; //取傭金info

export const getUsedGiftList = ({ str_date, end_date, time_type } = {}) => {
  return giftApi.get("/own", {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD HH:mm:ss")}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD HH:mm:ss")}`,
      time_type: time_type,
    },
  });
};
export const getFavoriteGameList = () => {
  return userApi.get("/getFavoriteGame");
}; //取最愛遊戲

export const getVipInfo = () => {
  return api.get("/vip/check");
};

export const getVipList = () => {
  return api.get("/vip/list");
};

export const getNewNotice = () => {
  return api.get("/notification");
};

export const getNewsList = ({ paramsData, pathParams } = {}) => {
  return api.get(`/news${pathParams ? `/${pathParams}` : ""}`, {
    params: { ...paramsData },
  });
};

export const getInboxList = () => {
  return api.get("/notification");
};

//取得玩家點擊過的遊戲
export const getUserGameClickList = () => {
  return userApi.get("/getUserGameClick");
};

export const getUserBalance = () => {
  return userApi.get("/singlewallet/get");
};

export const getMarquee = () => {
  return api.get("/marquees");
};

export const getBetHistory = ({ searchParams } = {}) => {
  const { str_date, end_date, platform, limit = 20, page = 1 } = searchParams;

  return api.get("/searchBetLog", {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD HH:mm:ss")}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD HH:mm:ss")}`,
      platform: platform,
      limit: limit,
      page: page,
    },
  });
};

export const getBufferHistory = ({ paramsData } = {}) => {
  const { str_date, end_date, limit = 20, page = 1 } = paramsData;
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");

  return api.get("/report/bufferlist", {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD")} ${now}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD")} ${past_24_hour}`,
      limit: limit,
      page: page,
    },
  });
};

export const getTestBalance = () => {
  return userApi.get("/find-member");
};

export const postTestBalance = () => {
  return userApi.post("/testForBalanceDetect");
};

export const getShortLink = () => {
  return api.get("/invite/url");
};

export const getTranslate = ({ lng } = {}) => {
  return repeatApi.get(`/translate/${lng}`);
};

export const getJackpot = () => {
  return api.get(`/jackpot`);
};

export const getRankingList = ({ paramsData } = {}) => {
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");
  const { start_time, end_time, limit } = paramsData || {};
  return api.get("/profit/ranking", {
    params: {
      // s_date: `${dayjs(start_time).format("YYYY-MM-DD")} ${now}`,
      // e_date: `${dayjs(end_time).format("YYYY-MM-DD")} ${past_24_hour}`,
      limit,
    },
  });
};

export const getTransactionLog = ({ paramsData } = {}) => {
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");
  const { start_time, end_time, limit } = paramsData || {};

  return api.get("/report/other", {
    params: {
      start_time: `${dayjs(start_time).format("YYYY-MM-DD")} ${now}`,
      end_time: `${dayjs(end_time).format("YYYY-MM-DD")} ${past_24_hour}`,
      limit,
    },
  });
};

export const getMyRebate = ({ paramsData } = {}) => {
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");
  const { start_time, end_time, limit } = paramsData || {};

  return api.get("/report/totalprofit", {
    params: {
      str_date: `${dayjs(start_time).format("YYYY-MM-DD")} ${now}`,
      end_date: `${dayjs(end_time).format("YYYY-MM-DD")} ${past_24_hour}`,
      limit,
    },
  });
};

export const getRanking = ({ paramsData } = {}) => {
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");
  const { start_time, end_time, limit } = paramsData || {};
  return api.get("/rank", {
    params: {
      start_time: `${dayjs(start_time).format("YYYY-MM-DD")} ${now}`,
      end_time: `${dayjs(end_time).format("YYYY-MM-DD")} ${past_24_hour}`,
      limit,
    },
  });
};

export const getNowCurrencyExchange = () => {
  return userApi.get("/getNowCurrencyExchange");
};

export const getEventList = ({ uid } = {}) => {
  return eventApi.get(`/list${uid ? `/${uid}` : ""}`);
};

export const getCarouselList = () => {
  return api.get(`/carousels`);
};

export const getEventReward = ({ event_uid } = {}) => {
  return eventApi.get(`/${event_uid}/memberGetEventRewards`);
};

export const getThirdPartyPayment = ({ paramsData } = {}) => {
  return api.get("/thirdPartyPayment/list", {
    params: { ...paramsData },
  });
};

export const getPlayerBetLog = ({ paramsData } = {}) => {
  const { str_date, end_date, platform, limit } = paramsData || {};
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");

  return api.get(`/report/netprofit`, {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD")} ${now}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD")} ${past_24_hour}`,
      platform,
      limit,
    },
  });
};

export const getDepositTransaction = ({ paramsData } = {}) => {
  const { str_date, end_date, limit, current_page } = paramsData || {};
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");

  return api.get("/report/deposit", {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD")} ${now}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD")} ${past_24_hour}`,
      limit,
      page: current_page,
    },
  });
};

export const getSendPointsTransaction = ({ paramsData } = {}) => {
  const { str_date, end_date, limit, current_page } = paramsData || {};
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");

  return api.get("/report/transPoint", {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD")} ${now}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD")} ${past_24_hour}`,
      limit,
      page: current_page,
    },
  });
};

export const getWithdrawTransaction = ({ paramsData } = {}) => {
  const { str_date, end_date, limit, current_page } = paramsData || {};
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");

  return api.get("/report/withdraw", {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD")} ${now}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD")} ${past_24_hour}`,
      limit,
      page: current_page,
    },
  });
};

export const getEventTransaction = ({ paramsData } = {}) => {
  const { str_date, end_date, limit, current_page } = paramsData || {};
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");

  return api.get("/report/eventsreward", {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD")} ${now}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD")} ${past_24_hour}`,
      limit,
      page: current_page,
    },
  });
};

export const getBetLog = ({ paramsData } = {}) => {
  const { str_date, end_date, platform, current_page, limit } =
    paramsData || {};
  const now = dayjs().format("HH:mm:ss");
  const past_24_hour = dayjs().subtract(24, "hour").format("HH:mm:ss");

  return api.get("/report/betlog", {
    params: {
      str_date: `${dayjs(str_date).format("YYYY-MM-DD")} ${now}`,
      end_date: `${dayjs(end_date).format("YYYY-MM-DD")} ${past_24_hour}`,
      platform,
      page: current_page,
      limit,
    },
  });
};

export const liveChatGetToken = () => {
  return api.get("/live-chat/getToken");
};

export const liveChatFreshToken = () => {
  return api.get("/live-chat/getFreshToken");
};

export const liveChatHasToken = () => {
  return api.get("/live-chat/hasToken");
};

export const getCurrentOnlineGamePlayer = () => {
  return api.get("/getGameOnline");
};

export const getStoreProductList = ({ pathParams, paramsData } = {}) => {
  return api.get(`/product${pathParams ? `/${pathParams}` : ""}`, {
    params: { ...paramsData },
  });
};

export const getStoreProductTypeList = () => {
  return api.get("/product/getProductType");
};

export const getStoreProductTransaction = () => {
  return api.get("/memberProductOrder");
};

export const getPlayerRewardList = ({ pathParams, paramsData } = {}) => {
  return api.get(`/memberRewards/member${pathParams ? `/${pathParams}` : ""}`, {
    params: { ...paramsData },
  });
};
