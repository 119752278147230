import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import PageAnimateWrapper from "../../components/Animate/pageAnimateWrapper";
import { Box, Button, Icon, Link, Text, useDisclosure } from "@chakra-ui/react";
import CustomInput from "../../components/InputComponents/customInput";
import { MdAccountCircle, MdAddBusiness } from "react-icons/md";
import { IoOptions } from "react-icons/io5";
import { FaAddressCard, FaCodeBranch } from "react-icons/fa";
import MarketConfirmModal from "./components/marketConfirmModal";
import MarketCloseDetailPage from "./components/marketCloseDetailPage";
import MarketDetailWrapper from "./components/marketDetailWrapper";
import { useTranslation } from "react-i18next";
import useCurrencySymbol from "../../hook/useCurrencySymbol";
import { HiTemplate } from "react-icons/hi";
import { useSelector } from "react-redux";
import useCurrentWager from "../../hook/useCurrentWager";
import WagerWrapper from "../StatusModal/WagerWrapper";

const MarketAddressV3 = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`market.${key}`, { ...props });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const current_symbol = useCurrencySymbol();

  const navigate = useNavigate();

  const parentProps = useOutletContext();
  const { product_detail, loading } = parentProps || {};

  const [data, setData] = useState({
    shipment_type: localStorage.getItem("shipment_type") || "",
    address: localStorage.getItem("address") || "",
    store_type: localStorage.getItem("store_type") || "",
    store_code: localStorage.getItem("store_code") || "",
    store_name: localStorage.getItem("store_name") || "",
    member_memo: localStorage.getItem("member_memo") || "",
  });
  const {
    shipment_type,
    address,
    store_type,
    store_code,
    store_name,
    member_memo,
  } = data;

  const handleSubmit = (e) => {
    e.preventDefault();

    onOpen();
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    localStorage.setItem(name, value);
  };

  useEffect(() => {
    localStorage.setItem("shipment_type", shipment_type);
  }, []);
  const walletCurrency = useSelector((state) => state.isWalletCurrency);
  const { wagering, restricted_amount, can_withdraw } =
    useCurrentWager({
      customCurrency: walletCurrency,
    }) || {};

  return (
    <>
      <MarketDetailWrapper>
        <MarketCloseDetailPage {...product_detail} />

        <Box>
          <PageAnimateWrapper>
            {" "}
            {can_withdraw ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                py="20px"
                gap="0px"
                px="20px"
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap="10px"
                  mb="10px"
                >
                  <Text
                    className="text-overflow"
                    fontSize="20px"
                    fontWeight={"bold"}
                    mb="5px"
                    color="v3-second-brand.500"
                  >
                    {i18n("write_delivery_information")}
                  </Text>
                </Box>{" "}
                <form onSubmit={handleSubmit}>
                  <Box mb="5px">
                    <CustomInput
                      props={{
                        type: "select",
                        label: i18n("delivery_way"),
                        labelProps: {
                          color: "v3-second-brand.500",
                        },
                        options: [
                          {
                            label: i18n("Select"),
                            value: "",
                          },
                          {
                            label: i18n("delivery"),
                            value: "delivery",
                            disabled: product_detail.is_delivery != 1,
                          },
                          {
                            label: i18n("store"),
                            value: "store",
                            disabled: product_detail.is_store_pickup != 1,
                          },
                        ],
                        required: true,
                        icon: <Icon as={IoOptions} />,
                        name: "shipment_type",
                        onChange: (e) => {
                          setData({ ...data, shipment_type: e.target.value });
                          localStorage.setItem("shipment_type", e.target.value);
                        },
                        //className
                        value: shipment_type,
                        variant: "none",
                        bg: "brand.100",
                        color: "v3-second-brand.500",
                      }}
                    />
                  </Box>
                  {shipment_type === "delivery" && (
                    <>
                      <Box mb="5px">
                        <CustomInput
                          props={{
                            type: "text",
                            label: i18n("delivery_address"),
                            labelProps: {
                              color: "v3-second-brand.500",
                            },
                            required: true,
                            icon: (
                              <Icon
                                color="v3-second-brand.500"
                                as={FaAddressCard}
                              />
                            ),
                            name: "address",
                            //className
                            variant: "none",
                            bg: "brand.100",
                            color: "v3-second-brand.500",
                            onChange: handleInput,
                            value: address,
                          }}
                        />
                      </Box>
                      {product_detail.shipping_fee_required == 1 && (
                        <Box>
                          <Text color="red.500">
                            {`*${i18n("this_product_delivery_fee_is", {
                              fee: product_detail?.delivery_fee || 0,
                              symbol: current_symbol,
                            })}`}
                          </Text>
                        </Box>
                      )}
                    </>
                  )}
                  {shipment_type === "store" && (
                    <>
                      <Box mb="5px">
                        <CustomInput
                          props={{
                            type: "select",
                            label: i18n("store_type"),
                            labelProps: {
                              color: "v3-second-brand.500",
                            },
                            required: true,
                            options: [
                              {
                                label: i18n("Select"),
                                value: "",
                              },
                              // {
                              //   label: "Family Mart",
                              //   value: "FAMI",
                              // },
                              {
                                label: i18n("UNIMART"),
                                value: "UNIMART",
                              },
                              // {
                              //   label: i18n("UNIMARTFREEZE"),
                              //   value: "UNIMARTFREEZE",
                              // },

                              // {
                              //   label: "Hi-Life",
                              //   value: "HILIFE",
                              // },
                            ],
                            icon: (
                              <Icon
                                color="v3-second-brand.500"
                                as={MdAddBusiness}
                              />
                            ),
                            name: "store_type",
                            //className
                            variant: "none",
                            bg: "brand.100",
                            color: "v3-second-brand.500",
                            onChange: handleInput,
                            value: store_type,
                          }}
                        />
                        {store_type === "UNIMARTFREEZE" ||
                        store_type === "UNIMART" ? (
                          <Link
                            color="red.500"
                            fontWeight={900}
                            fontSize="sm"
                            href="https://emap.pcsc.com.tw/"
                            target="_blank"
                          >
                            {i18n("7-11查詢店號")}
                          </Link>
                        ) : null}
                      </Box>
                      <Box mb="5px">
                        <CustomInput
                          props={{
                            type: "text",
                            label: i18n("store_code"),
                            labelProps: {
                              color: "v3-second-brand.500",
                            },
                            required: true,
                            icon: (
                              <Icon
                                color="v3-second-brand.500"
                                as={FaCodeBranch}
                              />
                            ),
                            name: "store_code",
                            //className
                            variant: "none",
                            bg: "brand.100",
                            color: "v3-second-brand.500",
                            onChange: handleInput,
                            value: store_code,
                          }}
                        />
                      </Box>
                      <Box mb="5px">
                        <CustomInput
                          props={{
                            type: "text",
                            label: i18n("store_name"),
                            labelProps: {
                              color: "v3-second-brand.500",
                            },
                            required: true,
                            icon: (
                              <Icon
                                color="v3-second-brand.500"
                                as={HiTemplate}
                              />
                            ),
                            name: "store_name",
                            //className
                            variant: "none",
                            bg: "brand.100",
                            color: "v3-second-brand.500",
                            onChange: handleInput,
                            value: store_name,
                          }}
                        />
                      </Box>
                    </>
                  )}
                  <Box mb="5px">
                    <CustomInput
                      props={{
                        type: "textarea",
                        label: i18n("member_memo"),
                        labelProps: {
                          color: "v3-second-brand.500",
                        },
                        icon: <Icon as={MdAccountCircle} />,
                        name: "member_memo",
                        //className
                        variant: "none",
                        bg: "brand.100",
                        color: "v3-second-brand.500",
                        onChange: handleInput,
                        value: member_memo,
                      }}
                    />
                  </Box>
                  <Box
                    color="v3-second-brand.500"
                    display={"flex"}
                    flexDirection={"column"}
                    gap="5px"
                  ></Box>
                  <Box mt="20px">
                    {/* <Checkbox my="10px" variant={`brandPrimary`}>
                同意條款說明
              </Checkbox> */}
                    <Button
                      bg="transparent"
                      type="submit"
                      bgGradient={"linear(to-b,#FC5151,#C91919)"}
                      borderRadius={"30px"}
                      w="100%"
                      size="lg"
                      _hover={{
                        bgGradient: "linear(to-b,#FC5151,#C91919)",
                      }}
                      _active={{
                        bgGradient: "linear(to-b,#FC5151,#C91919)",
                      }}
                    >
                      {i18n("submit_order")}
                    </Button>
                  </Box>
                </form>
              </Box>
            ) : (
              <WagerWrapper
                className={{
                  bg: "yellow.500",
                  mt: "50px",
                }}
                wagerProps={{ wagering, restricted_amount }}
              />
            )}
          </PageAnimateWrapper>
        </Box>
      </MarketDetailWrapper>
      <MarketConfirmModal
        orderDetail={data}
        productDetail={product_detail}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default MarketAddressV3;
