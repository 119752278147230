import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import WalletList from "./walletList";
import { COMMON_WEB_PATH, CURRENCY_SYMBOL } from "../../../constant";
import { IoIosAdd } from "react-icons/io";
import MyBalanceV3 from "../../../pages/Profile/components/balance_v3";
import { useNavigate, useLocation } from "react-router";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Icon,
  Image,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import CreditPage from "../../../utils/credit/credit_page";
import { MdOutlineAdd } from "react-icons/md";

const isCredit = process.env.REACT_APP_CREDIT == 1;
const BalanceV3 = () => {
  const memberData = useSelector((state) => state.isMemberInfo);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const baseBg = useColorModeValue("light.200", "dark.200");
  const baseHoverBg = useColorModeValue("light.300", "dark.500");

  const [openWallet, setOpenWallet] = useState(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  return (
    <Popover
      variant={"brandPrimary"}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
    >
      <Flex
        borderRadius={`full`}
        _hover={{
          bg: baseHoverBg,
        }}
        bg={baseBg}
        pr={isCredit ? "5px" : "3px"}
        pl="30px"
        alignItems="center"
        gap="15px"
        height="30px"
        cursor="pointer"
        position="relative"
        border="1px solid"
        borderColor="leaderBoard.v3-border"
        mr={{
          sm: "10px",
        }}
        justifyContent={"flex-end"}
        minW="130px"
      >
        <Image
          src={`${COMMON_WEB_PATH}/b69-home/v3-layout-icon/balance_money_icon.svg`}
          w="33px"
          h="35px"
          position="absolute"
          left="-2px"
        />
        <PopoverTrigger>
          <Box
            onClick={() => {
              if (pathname.includes("gamebox")) {
                return;
              } else {
                if (isCredit) {
                  return;
                }
                onToggle();
              }
            }}
            display={`flex`}
            alignItems={`center`}
            gap="5px"
          >
            <MyBalanceV3
              hiddenSymbol
              balanceStyle={{
                fontSize: "16px",
                fontWeight: "700",
              }}
            />
          </Box>
        </PopoverTrigger>
        <Link to="profile/deposit">
          <AbsoluteCenter w="20px" h="20px" left="100%">
            {/* <Image
              w="20px"
              h="20px"
              src={`${COMMON_WEB_PATH}/b69-home/v3-layout-icon/balance_deposit_icon.svg`}
            /> */}
            <Box
              w="20px"
              h="20px"
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              bg="brand.500"
              borderRadius={"100%"}
            >
              <Icon as={MdOutlineAdd} />
            </Box>
          </AbsoluteCenter>
        </Link>
      </Flex>
      {/* <WalletList onClose={onClose} /> */}
    </Popover>
  );
};

export default BalanceV3;
