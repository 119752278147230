import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { languageMapping } from "../i18n/config";
import i18next from "i18next";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const useProcessEventList = ({ event_list } = {}) => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency);

  const current_time = dayjs().format("YYYY-MM-DD HH:mm:ss");

  const process_event_list = useMemo(() => {
    return event_list
      ?.filter((item) => {
        const currency_attribute = item?.events_attributes?.find(
          (item) => item.attribute_currency === walletCurrency
        );
        return currency_attribute?.attribute_is_active === 1;
      })

      .map((item) => {
        const {
          auto_publish,
          start_time,
          end_time,
          event_code,
          event_show_type,
          events_copywriting,
          participating_platforms,
          uid,
          reset_cycle,
          events_attributes,
          is_show_carousel,
          is_show_pop_up,
          settlement_hour, //結算時
          settlement_interval, //結算種類,days,week
          settlement_interval_count, //結算間隔數值
        } = item || {};

        const process_settle_days = () => {
          const event_start_time = dayjs();
          const event_settle_time = event_start_time
            .add(settlement_interval_count, settlement_interval)
            .set("hour", settlement_hour);

          return event_settle_time.format("YYYY-MM-DD HH:00:00");
        };

        const find_event_attribute = events_attributes?.find(
          (item) => item.attribute_currency === walletCurrency
        );
        const {
          attribute_currency,

          attribute_subtype, //條件類型 > < =
          attribute_type,
          attribute_value, //條件值

          rewards,
        } = find_event_attribute || {};

        const {
          position,
          specify_wallet,
          type,
          base_value,

          enable_rewards_limit,
          enable_times_limit,
          limit_times,
          enable_amounts_limit,
          limit_amounts,

          enable_wagering_limit,
          wagering_limit_type,
          wagering_limit_value,
        } = rewards || {};
        const filter_events_copywriting = events_copywriting?.filter((item) => {
          return item?.title !== "";
        });

        const find_language_copywriting =
          filter_events_copywriting?.find(
            (item) => item.language === languageMapping(i18next.language)
          ) || filter_events_copywriting[0];
        return {
          is_between_time: dayjs(current_time).isBetween(start_time, end_time),
          start_time,
          end_time,
          event_code,
          event_show_type,
          is_show_carousel,
          reward_title: find_language_copywriting?.title,
          reward_slogan: find_language_copywriting?.slogan,
          reward_content: find_language_copywriting?.content,
          reward_sub_title: find_language_copywriting?.subtitle,
          copywriting_is_active: find_language_copywriting?.is_active,
          participating_platforms,
          uid,
          reset_cycle,
          reward_condition: {
            currency: attribute_currency,
            wagering: {
              enable_wagering_limit,
              wagering_limit_type,
              wagering_limit_value,
            },
            reward_limit: {
              enable_rewards_limit,
              enable_times_limit,
              limit_times,
              enable_amounts_limit,
              limit_amounts,
              attribute_rewards_type: type,
              attribute_rewards_value: base_value || 0,
            },
            rewards_type: {
              attribute_rewards_position: position,
              attribute_rewards_specify_wallet: specify_wallet,
              attribute_subtype,
              attribute_type,
              attribute_value,
            },
          },
          is_show_pop_up: is_show_pop_up,
          settle_time: process_settle_days(),
          auto_publish,
        };
      });
  }, [event_list, i18next, walletCurrency]);

  return process_event_list;
};

export default useProcessEventList;
