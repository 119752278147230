import React, { useMemo, useState } from "react";
import { CURRENCY_SYMBOL } from "../../../constant";
import {
  filterNumberColor,
  filterSign,
  formatNumber,
} from "../../../utils/formatNumber";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import { useTranslation } from "react-i18next";
import {
  Box,
  chakra,
  Divider,
  Flex,
  Icon,
  shouldForwardProp,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import TransactionTag from "./TransactionTag";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";
import i18next from "i18next";
import { languageMapping } from "../../../i18n/config";
import useVipInfo from "../../../hook/useVipInfo";
import MarketStatusTag from "./marketStatusTag";
import dayjs from "dayjs";
import { MdKeyboardArrowDown } from "react-icons/md";
import { isValidMotionProp, motion } from "framer-motion";
import { TbPencilCog, TbTrash } from "react-icons/tb";
import MarketModifyModal from "../../Market/components/marketModifyModal";
import { deleteProductOrder } from "../../../api/putApi";
import { useDispatch } from "react-redux";
import { notice } from "../../../redux/action/action";
import { waitTime } from "../../../utils/waitTime";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const MarketRecordDashboard = (props) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`transaction.${key}`);
  const i18n_market = (key, props) => t(`market.${key}`, { ...props });

  const cardBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const dispatch = useDispatch();

  const [openDetail, setOpenDetail] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { current_symbol } = useVipInfo();

  const {
    created_at,
    numbers = 0,
    status = 0,
    member_memo = "",
    memo = "",
    order_number = "-",
    product_point = 0,
    product,
    shipment_type = "-",
    address = "-",
    recipient,
    phone,
    email,
    uid,
    setTrigger,
    store_type,
    store_code,
    store_name,
    shipping_fee,
    shipment_company,
    freight_order,
  } = props || {};

  const product_title = useMemo(() => {
    const { product_language } = product || {};
    const find_product_lng =
      product_language?.find(
        (item) => item.language === languageMapping(i18next.language)
      ) || product_language?.[0];
    return find_product_lng?.product_name || "";
  }, [i18next]);

  const handleDelete = async () => {
    if (buttonLoading) return;
    deleteProductOrder({ uid }).then(async (data) => {
      dispatch(
        notice({
          title: i18n_market("delete_success"),
          content: "",
          type: "success",
        })
      );
      await waitTime(1000);
      await setButtonLoading(false);
      await setTrigger((prev) => !prev);
    });
  };

  return (
    <Box
      as="section"
      bg={cardBg}
      borderRadius={`common`}
      py="common-y"
      px="common-x"
    >
      <Flex justifyContent={`space-between`} gap="10px" alignItems={`start`}>
        <Text
          fontSize={`lg`}
          fontWeight={`semibold`}
          mb="7px"
          display="flex"
          alignItems={`center`}
          color="brand.500"
          className="text-overflow"
          maxW="70%"
        >
          {product_title}
        </Text>
        <MarketStatusTag status={status} />
      </Flex>
      <Flex alignItems={`center`}>
        <Text fontSize={`sm`} color="special.1">
          {i18n_market("product_point")}：
        </Text>
        <Text fontSize={`sm`} fontWeight={`bold`} color="special.1">
          {formatNumber(product_point)} {current_symbol}
        </Text>
      </Flex>
      <Flex alignItems={`center`}>
        <Text fontSize={`sm`} color="special.1">
          {i18n_market("numbers")}：
        </Text>
        <Text fontSize={`sm`} fontWeight={`bold`} color="special.1">
          {numbers}
        </Text>
      </Flex>
      <Divider my="5px" />
      <Flex alignItems={`center`}>
        <Text fontSize={`sm`} color="special.1">
          {i18n("transactionTime")}：
        </Text>
        <Text fontSize={`sm`} fontWeight={`bold`} color="special.1">
          {dayjs(created_at).format("YYYY-MM-DD HH:mm:ss")}
        </Text>
      </Flex>
      <Flex gap="10px" alignItems={`center`}>
        <Text minW="60px" fontSize={`sm`} color="special.1">
          {i18n("orderNumber")}：
        </Text>
        <Text
          fontSize={`sm`}
          className="text-overflow"
          fontWeight={`bold`}
          color="special.1"
        >
          {order_number || "#"}
        </Text>
        <CopyIcon copyLink={order_number || "#"} />
      </Flex>
      {status == 2 && (
        <>
          <Flex gap="10px" alignItems={`center`}>
            <Text minW="60px" fontSize={`sm`} color="special.1">
              {i18n_market("shipment_company")}：
            </Text>
            <Text
              fontSize={`sm`}
              className="text-overflow"
              fontWeight={`bold`}
              color="special.1"
            >
              {shipment_company || "#"}
            </Text>
          </Flex>
          <Flex gap="10px" alignItems={`center`}>
            <Text minW="60px" fontSize={`sm`} color="special.1">
              {i18n_market("freight_order")}：
            </Text>
            <Text
              fontSize={`sm`}
              className="text-overflow"
              fontWeight={`bold`}
              color="special.1"
            >
              {freight_order || "#"}
            </Text>
          </Flex>
        </>
      )}
      <Flex
        onClick={() => setOpenDetail(!openDetail)}
        fontSize={`sm`}
        fontWeight={`bold`}
        color="special.1"
        cursor="pointer"
        alignItems={"center"}
        justifyContent={"center"}
        mt="5px"
      >
        <Text>{i18n_market("expand_detail")}</Text>
        <Icon as={MdKeyboardArrowDown} />
      </Flex>
      <ChakraBox
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: openDetail ? "auto" : 0,
          opacity: openDetail ? 1 : 0,
        }}
        overflow="hidden"
        transition={{ height: { duration: 0.3 }, opacity: { duration: 0.2 } }}
        fontSize={`xs`}
        color="special.1"
      >
        <Divider my="5px" />
        <Box position="relative">
          {status == 0 && (
            <Flex position={"absolute"} top="0px" right="0px" gap="5px">
              <Icon
                as={TbPencilCog}
                fontSize="18px"
                cursor="pointer"
                onClick={() => {
                  if (buttonLoading) return;
                  onOpen();
                }}
              />
              <Icon
                as={TbTrash}
                fontSize="18px"
                cursor="pointer"
                onClick={handleDelete}
              />
            </Flex>
          )}
          <Flex alignItems={`center`}>
            <Text>{i18n_market("member_memo")}：</Text>
            <Text fontWeight={`bold`}>{member_memo || "-"}</Text>
          </Flex>
          <Flex alignItems={`center`}>
            <Text>{i18n("memo")}：</Text>
            <Text fontWeight={`bold`}>{memo || "-"}</Text>
          </Flex>

          <Flex alignItems={`center`}>
            <Text>
              {i18n_market("this_product_delivery_fee_is", {
                fee: formatNumber(shipping_fee),
                symbol: "",
              })}
            </Text>
          </Flex>
          <Flex alignItems={`center`}>
            <Text>{i18n_market("shipment_type")}：</Text>
            <Text fontWeight={`bold`}>{i18n_market(shipment_type) || "-"}</Text>
          </Flex>
          {shipment_type == "delivery" ? (
            <>
              <Flex alignItems={`center`}>
                <Text>{i18n_market("address")}：</Text>
                <Text fontWeight={`bold`}>{address || "-"}</Text>
              </Flex>
            </>
          ) : (
            <>
              <Flex alignItems={`center`}>
                <Text>{i18n_market("store_type")}：</Text>
                <Text fontWeight={`bold`}>{store_type || "-"}</Text>
              </Flex>
              <Flex alignItems={`center`}>
                <Text>{i18n_market("store_code")}：</Text>
                <Text fontWeight={`bold`}>{store_code || "-"}</Text>
              </Flex>
              <Flex alignItems={`center`}>
                <Text>{i18n_market("store_name")}：</Text>
                <Text fontWeight={`bold`}>{store_name || "-"}</Text>
              </Flex>
            </>
          )}
          <Flex alignItems={`center`}>
            <Text>{i18n_market("recipient")}：</Text>
            <Text fontWeight={`bold`}>{recipient || "-"}</Text>
          </Flex>

          <Flex alignItems={`center`}>
            <Text>{i18n_market("phone")}：</Text>
            <Text fontWeight={`bold`}>{phone || "-"}</Text>
          </Flex>
          <Flex alignItems={`center`}>
            <Text>{i18n_market("email")}：</Text>
            <Text fontWeight={`bold`}>{email || "-"}</Text>
          </Flex>
        </Box>
      </ChakraBox>
      <MarketModifyModal
        isOpen={isOpen}
        onClose={onClose}
        setTrigger={setTrigger}
        orderDetail={{
          created_at,
          numbers,
          status,
          member_memo,
          memo,
          order_number,
          product_point,
          product,
          shipment_type,
          address,
          recipient,
          phone,
          email,
          uid,
          store_type,
          store_code,
          store_name,
        }}
      />
    </Box>
  );
};

export default MarketRecordDashboard;
