import React, { useEffect, useMemo, useState } from "react";
import DetailWrapper from "../../../components/detailWrapper";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import { HiChevronLeft } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AlreadyBind from "./alreadyBind";
import { notice } from "../../../redux/action/action";
import FromWithdrawHint from "../../Withdraw/components/fromWithdrawHint";
import { Link, useSearchParams } from "react-router-dom";
import { CURRENCY_MENU } from "../../../i18n/config";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Text,
  ListIcon,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import CustomInput from "../../../components/InputComponents/customInput";
import { FaArrowRight, FaBirthdayCake, FaIdCard } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { MdCheckCircle } from "react-icons/md";
import { waitTime } from "../../../utils/waitTime";
import dayjs from "dayjs";
import { bindCardInformation } from "../../../api/postApi";
import useAgentMode from "../../../hook/useAgentMode";

const iconStyle = "text-gray-300";

const BindIdCard = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_formHint = (key) => t(`formHint.${key}`);

  const dispatch = useDispatch();
  const memberData = useSelector((state) => state.isMemberInfo);
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const { bankAccount } = memberData || {};

  const currentAgentMode = useAgentMode();

  let [searchParams, setSearchParams] = useSearchParams(); //取得網址參數
  const navigate = useNavigate();
  const location = useLocation();

  const textColor = useColorModeValue("dark.100", "light.100");
  const noteBg = useColorModeValue("light.100", "dark.100");

  const currencyList = useMemo(() => {
    return Object.keys(isCurrencyExchange)?.filter((item) => {
      return isCurrencyExchange[item].is_crypto == 0;
    });
  }, [isCurrencyExchange]);

  const [selectedCurrency, setSelectedCurrency] = useState(
    //取得網址參數的幣別 從pleaseSetUserIDCard.js傳過來"../settings/bind-id-card?currency=${selectedCurrency}"
    searchParams.get("currency")
    //searchParams取網址的?後面的參數:currency=${selectedCurrency}
  );

  const bound = useMemo(() => {
    //判斷選的幣別selectedCurrency是否已綁定
    if (!bankAccount || !bankAccount[selectedCurrency]) {
      return false;
    }
    const uid = bankAccount[selectedCurrency]?.uid;
    if (uid) {
      return true;
    } else {
      return false;
    }
  }, [selectedCurrency, bankAccount]);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [data, setData] = useState({
    id_card: localStorage.getItem("id_card") || "",
    // birth: localStorage.getItem("birth") || "",
    currency: localStorage.getItem("currency") || "",
  });
  const [uploadIdCard, setUploadIdCard] = useState(null);

  const { id_card, birth, currency } = data;
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value == "null") {
      dispatch(
        notice({
          title: i18n_common("pleaseConfirmInputContentIsCorrect"),
          type: "error",
        })
      );
      return;
    }
    setData((prev) => ({ ...prev, [name]: value.trim() }));
    localStorage.setItem(name, value.trim());

    // 如果改變的是幣別，則更新 selectedCurrency 的值
    if (name === "currency") {
      setSelectedCurrency(value.trim());
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("step") && memberData.isBindAccount == 0) {
      localStorage.setItem("step", "1");
    }
  }, []);

  const unboundCurrency = useMemo(() => {
    //判斷哪些幣別未綁定
    // const list = currencyList.filter((currency) => {
    //   // 如果 bankAccount 中沒有這個幣別的資訊，或者該幣別的 uid 為空，則認為這個幣別未綁定 才能出現在下拉選單內
    //   return (
    //     !bankAccount || !bankAccount[currency] || !bankAccount[currency].uid
    //   );
    // });;
    const list = currencyList;
    const selectOptions = list.map((item) => {
      const isBound =
        !bankAccount || !bankAccount[item] || !bankAccount[item].uid;
      return {
        label: `${item}${
          !isBound ? `(${i18n_formHint("youAlreadyBindCard")})` : ""
        }`,
        value: item,
        disabled: !isBound,
      };
    });
    return [
      {
        label: i18n("form.pleaseSelect"),
        value: "",
      },
      ...selectOptions,
    ];
  }, [bankAccount, currencyList]);

  const allCurrenciesBound = useMemo(() => {
    //判斷是否全部幣別都已綁定
    return CURRENCY_MENU.every((currency) => {
      // 如果 bankAccount 中有這個幣別的資訊，並且該幣別的 uid 不為空，則認為這個幣別已綁定
      return (
        bankAccount &&
        bankAccount[currency.value] &&
        bankAccount[currency.value].uid
      );
    });
  }, [bankAccount]);

  const handleNextStep = async (e) => {
    e.preventDefault();
    if (currentAgentMode == "cash") {
      //現金版要填寫銀行資訊，第二步為銀行資訊
      setButtonLoading(true);
      localStorage.setItem("step", "2");
      localStorage.setItem("currency_code", selectedCurrency);
      await waitTime(1000);
      setButtonLoading(false);
      navigate("../bind-bank-account");
    } else {
      //信用版不須填寫銀行資訊，直接送出即可
      const uploadParamsList = ["id_card"];
      let bodyFormData = new FormData();
      setButtonLoading(true);

      if (bankAccount?.id_pic != 1) {
        bodyFormData.append("id_pic", uploadIdCard);
      }

      for (let i = 0; i < uploadParamsList.length; i++) {
        bodyFormData.append(
          uploadParamsList[i],
          localStorage.getItem(uploadParamsList[i])
        );
      }

      bodyFormData.append("currency_code", localStorage.getItem("currency"));

      bindCardInformation({
        formData: bodyFormData,
      })
        .then((data) => {
          dispatch({ type: "GET_MEMBER_DATA" });
          dispatch(
            notice({ title: i18n_formHint("setSuccess"), type: "success" })
          );
          localStorage.clear();
          navigate("../bind-success");
        })
        .catch((err) => {})
        .finally(() => {
          setButtonLoading(false);
        });
    }
  };
  console.log(localStorage.getItem("upload"));
  return (
    <>
      <DetailWrapper
        wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[10] bg-gradient-to-br overflow-x-[unset]`}
      >
        <CloseDetailPage
          hasPrev={
            <HiChevronLeft
              className="cursor-pointer"
              onClick={() => {
                navigate("..");
                localStorage.clear();
              }}
            />
          }
          title={i18n("bindIdCard")}
        />

        {!bound && !allCurrenciesBound ? (
          <Box
            as="article"
            display="flex"
            flexDirection={`column`}
            gap="15px"
            p="15px"
            fontWeight={`medium`}
            color={textColor}
          >
            <Alert
              status="warning"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {i18n("idCard.inputIdInformation")}
              </AlertTitle>
              <AlertDescription fontSize="xs">
                {i18n("idCard.hint.1")}{" "}
              </AlertDescription>
              <Box p="5px" borderRadius={`common`}>
                <List spacing={3}>
                  <ListItem fontSize="sm">
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    1. {i18n("idCard.hint.2")}({i18n("idCard.hint.2-1")})
                  </ListItem>
                  {/* <ListItem fontSize="sm">
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    2. {i18n("idCard.hint.3")}({i18n("idCard.hint.3-1")})
                  </ListItem> */}
                </List>
              </Box>
            </Alert>

            <Divider />
            <form onSubmit={handleNextStep}>
              <Box>
                <CustomInput
                  props={{
                    label: `${i18n("form.pleaseSelect")} ${i18n(
                      "form.currency_code"
                    )}`,
                    type: "select",
                    name: "currency",
                    value: currency,
                    onChange: handleChange,
                    options: unboundCurrency,
                    required: true,
                  }}
                />
              </Box>
              <Box>
                <CustomInput
                  props={{
                    label: i18n("form.inputId"),
                    type: "text",
                    name: "id_card",
                    value: id_card,
                    placeholder: i18n("form.inputId"),
                    onChange: handleChange,
                    disabled: !currency,
                    icon: <FaIdCard className={iconStyle} />,
                    required: true,
                  }}
                />
              </Box>

              {currentAgentMode == "credit" ? (
                <Box>
                  <CustomInput
                    props={{
                      onChange: (e) => {
                        const selectedFile = e.target.files[0];
                        setUploadIdCard(selectedFile);
                      },
                      accept: "image/png",
                      label: i18n("form.uploadIdCard"),
                      required: true,
                      type: "file",
                      name: "upload",
                      icon: <FaIdCard className={iconStyle} />,
                    }}
                  />
                </Box>
              ) : null}
              {/* <Box>
                <CustomInput
                  props={{
                    label: `${i18n("form.inputBirthday")}(DD/MM/YYYY)`,
                    type: "date",
                    name: "birth",
                    value: birth,
                    placeholder: i18n("form.inputId"),
                    onChange: handleChange,
                    disabled: !selectedCurrency,
                    max: dayjs().format("YYYY-MM-DD"),
                    icon: <FaBirthdayCake className={iconStyle} />,
                    required: true,
                  }}
                />
              </Box> */}
              <Divider my="10px" />
              <Button
                variant={`brandPrimary`}
                isDisabled={
                  !Object?.keys(data).every((item) => data?.[item] !== "")
                }
                isLoading={buttonLoading}
                type="submit"
                size="md"
                rightIcon={<FaArrowRight />}
                w="100%"
              >
                {currentAgentMode == "cash"
                  ? i18n_common("nextStep")
                  : i18n_common("updateData")}
              </Button>
            </form>
          </Box>
        ) : (
          <AlreadyBind
            children={<FromWithdrawHint selectedCurrency={currency} />}
          />
        )}
      </DetailWrapper>
    </>
  );
};

export default BindIdCard;
