import React from "react";
import DetailWrapper from "../../../components/detailWrapper";
import WagerWrapper from "../../StatusModal/WagerWrapper";
import useCurrentWager from "../../../hook/useCurrentWager";
import { useSelector } from "react-redux";

const MarketDetailWrapper = ({ children }) => {
  return (
    <DetailWrapper
      closeAnimate
      customBg="brand.200"
      wrapperClassName={
        "!top-[unset] !bottom-0 md:rounded-[15px] !h-[calc(100%-50px)] !px-0 md:min-w-[572px] md:min-h-[700px] md:max-w-[572px] md:max-h-[700px] md:overflow-y-auto"
      }
    >
      {children}
    </DetailWrapper>
  );
};

export default MarketDetailWrapper;
