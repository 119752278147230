import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import useCheckLogin from "./useCheckLogin";

const useAgentMode = () => {
  //此hook為獲取當前代理的模式，有分為"cash"現金版、"credit"信用版
  const isMemberInfo = useSelector((state) => state.isMemberInfo);
  const { isLogin } = useCheckLogin();

  const current_mode = useMemo(() => {
    if (isLogin) {
      return isMemberInfo?.mode?.toLowerCase();
    } else {
      //沒登入回傳credit做為預設顯示
      return "credit";
    }
  }, [isMemberInfo?.mode, isLogin]);
  return current_mode;
};

export default useAgentMode;
