import { Box } from "@chakra-ui/react";
import CarouselV3 from "../../../components/Carousel_v3";
import MarqueeListV2 from "../../../components/marquee/marqueeList_v2";
import MarketType from "./marketType";
import useStoreProductType from "../hooks/useStoreProductType";
import { useSelector } from "react-redux";
import useAgentMode from "../../../hook/useAgentMode";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MarketWrapper = ({ children }) => {
  const { product_type_loading } = useStoreProductType();

  const currentAgentMode = useAgentMode();

  const navigate = useNavigate();

  const isStoreProductTypeList = useSelector(
    (state) => state.isStoreProductTypeList
  );

  useEffect(() => {
    if (currentAgentMode == "cash") {
      navigate("/");
      return;
    }
  }, [currentAgentMode]);

  return (
    <>
      <Box as="section" pt="home-space" px="common-y">
        <CarouselV3 carouselType="market" />
      </Box>
      <Box as="section" pt="home-space" px="common-y">
        <MarqueeListV2 />
      </Box>
      <Box as="section" pt="home-space" px="common-y">
        <MarketType store_product_type_list={isStoreProductTypeList} />
      </Box>
      {children}
    </>
  );
};

export default MarketWrapper;
