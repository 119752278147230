import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LoadingReward from "../../../components/Loading/LoadingReward";
import { useSelector } from "react-redux";
import useProcessEventList from "../../../hook/useProcessEventList";
import { getPlayerRewardList } from "../../../api/getApi";
import NotFound from "../../../components/ProgressBlock/NotFound";
import ReceiveRewardsDashboard from "./receiveRewardsDashboard";
import { waitTime } from "../../../utils/waitTime";
import Paginator from "../../Transaction/components/paginator";

const ReceiveRewardsList = ({ tab, selected } = {}) => {
  const globalTrigger = useSelector((state) => state.isGlobalTrigger);
  const walletCurrency = useSelector((state) => state.isWalletCurrency);
  const eventList = useSelector((state) => state.isEventList);

  const processEventList = useProcessEventList({
    event_list: eventList,
  });

  const [apiCalling, setApiCalling] = useState(true);
  const [rewardsList, setRewardsList] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    from: null,
    to: null,
    total: null,
  });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      await setApiCalling(true);

      await getPlayerRewardList({
        pathParams: selected,
        paramsData: { limit: 15, page: currentPage },
      })
        .then((res) => {
          const { data, current_page, last_page, from, to, total } = res || {};

          setPagination({
            current_page,
            last_page,
            from,
            to,
            total,
          });
          setRewardsList(data);
          setApiCalling(false);
        })
        .catch((error) => {
          setApiCalling(false);
        });
    };

    fetchData();
  }, [selected, processEventList, currentPage]);
  return (
    <Flex
      direction={"column"}
      w="100%"
      //   overflowX={direction && "scroll"}
      gap="10px"
    >
      <LoadingReward commonLoading={apiCalling}>
        {rewardsList?.length !== 0 ? (
          rewardsList?.map((item) => {
            return <ReceiveRewardsDashboard {...item} />;
          })
        ) : (
          <NotFound />
        )}
        <Box>
          <Paginator
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pagination={pagination}
          />
        </Box>
      </LoadingReward>
    </Flex>
  );
};

export default ReceiveRewardsList;
