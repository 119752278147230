import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaTriangleExclamation } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { openChat } from "../../redux/action/member/memberAction";
import { formatNumber } from "../../utils/formatNumber";

const WagerWrapper = ({ children, className, wagerProps, isWrapper }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`profile.${key}`);
  const i18n_gamebox = (key) => t(`gamebox.${key}`);
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_vip = (key, props) => t(`vip.${key}`, { ...props });

  const { wagering, restricted_amount } = wagerProps || {};

  const memberData = useSelector((state) => state.isMemberInfo);
  const dispatch = useDispatch();

  const textColor = useColorModeValue("dark.100", "light.100");

  const { status } = memberData || {};

  return (
    <>
      <Alert
        {...className}
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle color={textColor} mt={4} mb={1} fontSize="lg">
          {i18n_common("notice_you")}!!
        </AlertTitle>
        <AlertDescription color={textColor} maxWidth="sm">
          <Text fontWeight={700} mb="15px">
            {i18n("wagerHint")}
          </Text>
          {/* <Button
            mt="15px"
            type="button"
            variant={`brandPrimary`}
            onClick={() => {
              dispatch(openChat());
            }}
            size="md"
            fontWeight={`medium`}
          >
            {i18n_gamebox("contactCustomerServiceNow")}
          </Button> */}
          <Flex justifyContent={"space-between"}>
            <Text fontWeight={"900"}>{i18n_vip("withdrawNeedCondition")}</Text>
            <Flex direction={`column`} alignItems={`center`}>
              <Text
                as="span"
                color={textColor}
                fontSize="md"
                fontWeight={`900`}
                letterSpacing={`tighter`}
                className="text-overflow"
              >
                {formatNumber(wagering, { point: false })} /{" "}
                {formatNumber(restricted_amount, {
                  point: false,
                })}
              </Text>
            </Flex>
          </Flex>
        </AlertDescription>
      </Alert>
    </>
  );
};

export default WagerWrapper;
