import {
  Box,
  Button,
  Image,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import {
  animate,
  isValidMotionProp,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { useTranslation } from "react-i18next";
import { COMMON_WEB_PATH } from "../../constant";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const RouletteReward = ({ reward, claimButton }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_roulette = (key) => t(`roulette.${key}`);

  const boxBg = useColorModeValue("light.200", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");
  const goldMaskBg = useColorModeValue(
    "radial-gradient(rgba(242,187,47,.44),#ffffff 80%)",
    "radial-gradient(rgba(242,187,47,.2),#191A1B 80%)"
  );

  const count = useMotionValue(0);
  const rounded = useTransform(count, (value) => value.toFixed(2));

  const [textReward, setTextReward] = useState("");

  const navigate = useNavigate();

  const handleClaim = () => {
    navigate("/profile/transaction?tab=event");
  };

  useEffect(() => {
    if (typeof reward?.reward == "string") {
      setTextReward(reward?.reward);
    }
    const animation = animate(count, Number(reward?.reward), {
      duration: 3,
      ease: "easeOut",
    });

    return animation.stop;
  }, [reward?.reward]);

  return (
    <Box
      w="100%"
      h="100%"
      display="flex"
      alignItems={`center`}
      justifyContent={`center`}
    >
      <ChakraBox
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
        }}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
          variant: "spring",
        }}
        position="relative"
        w="24.25rem"
        h="21.875rem"
        maxW="100%"
        // transform={`translate(-50%,-50%)`}
        //   left="50%"
        //   top="50%"
        maxH="100%"
        borderRadius={`common`}
      >
        <Image
          position="absolute"
          h="31.25rem"
          top="-5.3125rem"
          left="-0rem"
          className="reward-spin"
          src={`${COMMON_WEB_PATH}/common_image/Roulette/roulette-gold-light.webp`}
          w="31.25rem"
          zIndex={2}
        />
        <Box
          style={{
            backgroundImage: goldMaskBg,
          }}
          bgPos={"center -8.125rem"}
          bgSize={"100% 90%"}
          bgRepeat={"no-repeat"}
          position="relative"
          zIndex={3}
          id="wrap"
          bg={boxBg}
        >
          <Image
            position="absolute"
            w="26.875rem"
            h="26.875rem"
            left="0rem"
            top="-3.75rem"
            zIndex={2}
            src={`${COMMON_WEB_PATH}/common_image/Roulette/roulette-money.webp`}
          />
          <Box
            position="relative"
            zIndex={3}
            h="100%"
            p="2.75rem 1.75rem"
            textAlign={`center`}
            display="flex"
            gap="5px"
            flexDirection={`column`}
          >
            <Text
              letterSpacing={`tighter`}
              fontSize="25px"
              fontWeight={`800`}
              color={textColor}
            >
              {i18n_roulette("CONGRATUALTIONS")}
            </Text>
            {/* <Text fontSize="16px" fontWeight={`600`} color={`special.1`}>
              {i18n_roulette("You've got free money to bet on our games!")}
            </Text> */}
            <Text
              backgroundImage="linear-gradient(to bottom,#FFB03B,#FCEE21,#FFFF89,#FDDB3E,#FFDB49,#FFEB7D,#FDA60A)"
              filter="drop-shadow(2px 2px 0 #C55B1A)"
              backgroundClip={"text"}
              display="flex"
              alignItems="center"
              fontSize="45px"
              fontStyle={`italic`}
              fontWeight={`900`}
              justifyContent={`center`}
              my="20px"
            >
              <ChakraBox>
                {typeof reward?.reward == "string" ? textReward : rounded}
              </ChakraBox>
              {typeof reward?.reward == "number" && reward?.symbol}
            </Text>
            <Button
              onClick={handleClaim}
              isDisabled={claimButton}
              variant={`brandPrimary`}
              type="button"
            >
              {i18n_roulette("Claim Rewards")}
            </Button>
          </Box>
        </Box>
      </ChakraBox>
    </Box>
  );
};

export default RouletteReward;
