import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import SelectMethod from "./components/SelectMethod";

import { animatePage } from "../../animateConstant";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import LoadingBank from "../../components/Loading/LoadingBank";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import PaymentCurrencySettings from "../Settings/components/PaymentCurrencySettings";
import NotFound from "../../components/ProgressBlock/NotFound";
import FreezeWrapper from "../StatusModal/FreezeWrapper";
import useCurrentWager from "../../hook/useCurrentWager";
import WagerWrapper from "../StatusModal/WagerWrapper";
import PageAnimateWrapper from "../../components/Animate/pageAnimateWrapper";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const DepositStepOne = ({ isLoading, paymentList, selectedCurrencyConfig }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`deposit.${key}`);

  const { vip, third_party } = paymentList || {};
  const { selectedCurrency, setSelectedCurrency } =
    selectedCurrencyConfig || {};

  const walletCurrencyCode = useSelector((state) => state.isWalletCurrency);

  const textColor = useColorModeValue("dark.100", "light.100");

  const handleCurrencyChange = useCallback(
    (currency) => {
      setSelectedCurrency(currency);
    },
    [selectedCurrencyConfig]
  );

  const filter_same_third_party = useMemo(() => {
    return third_party?.reduce((acc, cur) => {
      const existing = acc.find((item) => item.method_name === cur.method_name);
      if (existing) {
        existing.min = Math.min(existing.min, cur.min);
        existing.max = Math.max(existing.max, cur.max);
      } else {
        acc.push({ ...cur });
      }
      return acc;
    }, []);
  }, [third_party]);

  const { wagering, restricted_amount, can_withdraw } =
    useCurrentWager({
      customCurrency: selectedCurrency,
    }) || {};

  return (
    <PageAnimateWrapper>
      <CloseDetailPage title={i18n("deposit")} />
      <FreezeWrapper>
        <Box as="section">
          <Box as="section" mb="24px" p="2px">
            <Flex
              alignItems={`center`}
              justifyContent={`space-between`}
              w="100%"
              gap="10px"
              my="5px"
            >
              <Text
                fontWeight={`semibold`}
                fontSize={`lg`}
                mb="0"
                color={textColor}
              >
                {i18n("methodText")}
              </Text>
              <PaymentCurrencySettings
                filterFrontStatus
                onCurrencyChange={handleCurrencyChange}
                selectedCurrency={selectedCurrency}
              />
            </Flex>

            <Divider borderColor="gray.300" my="10px" />
            {isCredit ? (
              can_withdraw ? (
                <LoadingBank mapIndex={5} commonLoading={isLoading}>
                  {filter_same_third_party?.length === 0 &&
                  vip?.length === 0 ? (
                    <NotFound />
                  ) : null}
                  {filter_same_third_party?.map((item, index) => {
                    const min = item.min; //最小金額
                    const max = item.max; //最大金額
                    const is_duplicate = item.duplicate;
                    return (
                      <SelectMethod
                        currency={walletCurrencyCode}
                        isDuplicate={is_duplicate}
                        min={min}
                        max={max}
                        methodProps={item}
                      />
                    );
                  })}
                  {vip?.map((item, index) => {
                    return (
                      <SelectMethod
                        currency={walletCurrencyCode}
                        methodProps={{
                          ...item,
                          method_name: `BankTransfer`,
                          isVip: true,
                        }}
                      />
                    );
                  })}
                </LoadingBank>
              ) : (
                <WagerWrapper wagerProps={{ wagering, restricted_amount }} />
              )
            ) : (
              <LoadingBank mapIndex={5} commonLoading={isLoading}>
                {filter_same_third_party?.length === 0 && vip?.length === 0 ? (
                  <NotFound />
                ) : null}
                {filter_same_third_party?.map((item, index) => {
                  const min = item.min; //最小金額
                  const max = item.max; //最大金額
                  const is_duplicate = item.duplicate;
                  return (
                    <SelectMethod
                      currency={selectedCurrency}
                      isDuplicate={is_duplicate}
                      min={min}
                      max={max}
                      methodProps={item}
                    />
                  );
                })}
                {vip?.map((item, index) => {
                  return (
                    <SelectMethod
                      currency={walletCurrencyCode}
                      methodProps={{
                        ...item,
                        method_name: `BankTransfer`,
                        isVip: true,
                      }}
                    />
                  );
                })}
              </LoadingBank>
            )}
          </Box>
        </Box>
      </FreezeWrapper>
    </PageAnimateWrapper>
  );
};

export default DepositStepOne;
