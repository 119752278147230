import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { COMMON_WEB_PATH } from "../../../constant";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/formatNumber";
import { receiveReward } from "../../../api/postApi";
import { useDispatch } from "react-redux";
import { notice } from "../../../redux/action/action";
import { waitTime } from "../../../utils/waitTime";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { languageMapping } from "../../../i18n/config";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";
import useCurrencySymbol from "../../../hook/useCurrencySymbol";

const ReceiveRewardsDashboard = (props) => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`receive-reward.${key}`, { ...props });
  const i18n_reward_dashboard = (key, props) =>
    t(`reward.dashboard.${key}`, { ...props });

  const cardBg = useColorModeValue("light.100", "dark.100");
  const titleColor = useColorModeValue("dark.100", "light.100");
  const textColor = useColorModeValue("dark.300", "light.300");
  const rewardTextColor = useColorModeValue("special.1", "special.gold");

  const [buttonLoading, setButtonLoading] = useState(false);
  const [fetchingStatusLoading, setFetchingStatusLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {
    status,
    claimed_amount,
    member_rewards_claimed_log,
    member_rewards_redeemed_log,
    events_attributes,
    rewards,
    currency,
    events_attributes_uid,
  } = props || {};

  const current_symbol = useCurrencySymbol({ custom_currency: currency });

  const { expired_time, claim_time, member_rewards_uid } =
    member_rewards_claimed_log?.[0] || {};

  const { redeem_time } = member_rewards_redeemed_log?.[0] || {};

  const copywriting = useMemo(() => {
    const event_copywriting =
      events_attributes?.events_list?.events_copywriting;
    return event_copywriting?.find(
      (item) => item.language == languageMapping(i18next.language)
    )?.title
      ? event_copywriting?.find(
          (item) => item.language == languageMapping(i18next.language)
        )
      : event_copywriting?.[0];
  }, [events_attributes, i18next.language]);

  const reward_title = useMemo(() => {
    if (events_attributes_uid !== null) {
      return copywriting?.title;
    } else {
      return rewards?.name;
    }
  }, [copywriting, events_attributes_uid, rewards]);

  const reward_type = useMemo(() => {
    if (rewards?.type == "multiplier") {
      return "amounts";
    } else {
      return rewards?.type;
    }
  }, [rewards?.type]);

  const handleUseReward = async (uid) => {
    setButtonLoading(true);
    try {
      await receiveReward({
        paramsData: {
          memberRewards_uid: uid,
        },
      }).then((data) => {
        dispatch({ type: "UPDATE_EVENT_LIST" });
        dispatch({ type: "GET_MEMBER_DATA" });
        dispatch(
          notice({
            content: "Receive Success",
            type: "success",
          })
        );
      });
      await waitTime(1000);
      navigate("../rewards?tab=history");
      setButtonLoading(false);
    } catch (error) {
      setButtonLoading(false);
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={`column`}
        gap="15px"
        justifyContent={`space-between`}
        as="section"
        position="relative"
        p="common-x"
        borderRadius={`common`}
        borderLeft={"4px solid"}
        borderColor={status == 1 ? "green.500" : "gray.400"}
        bg={cardBg}
        overflow="hidden"
        backgroundImage={`${COMMON_WEB_PATH}/reward_icon/background_depoist.avif`}
        backgroundSize={`100% 100%`}
      >
        <Flex alignItems={"center"} gap="5px">
          <Text fontWeight={"bold"} fontSize="lg">
            {reward_title}
          </Text>
          <Flex>
            <Text fontWeight={"bold"} color="special.gold" fontSize="sm">
              #{rewards?.uid}
            </Text>
            <CopyIcon text={rewards?.uid} />
          </Flex>
        </Flex>
        <Flex direction={`column`} gap="2px">
          <>
            <Box maxW={`80%`}>
              <Flex
                color={textColor}
                justifyContent={`space-between`}
                fontSize="11px"
                gap="10px"
              >
                <Text>{i18n("claim_time")}</Text>
                <Text>{claim_time}</Text>
              </Flex>

              {status == 1 && (
                <Flex
                  color={textColor}
                  justifyContent={`space-between`}
                  fontSize="11px"
                  gap="10px"
                >
                  <Text>{i18n("expired_time")}</Text>
                  <Text>{expired_time}</Text>
                </Flex>
              )}
              {status == 2 && (
                <Flex
                  color={textColor}
                  justifyContent={`space-between`}
                  fontSize="11px"
                  gap="10px"
                >
                  <Text>{i18n("used_time")}</Text>
                  <Text>{redeem_time}</Text>
                </Flex>
              )}
            </Box>
            <Divider my="5px" />
            <Flex
              alignItems={`center`}
              color={"green.500"}
              justifyContent={`space-between`}
            >
              <Text as="p" fontSize="sm" fontWeight={`bold`} mb="0px">
                {i18n("receive_amount")}
              </Text>
              <Text
                as="p"
                fontSize="sm"
                color={rewardTextColor}
                fontWeight={`bold`}
                mb="0px"
              >
                {formatNumber(claimed_amount, {
                  point: rewards?.type != "roulettes",
                })}{" "}
                {i18n_reward_dashboard(reward_type, {
                  current_symbol: current_symbol,
                })}
              </Text>
            </Flex>
            {rewards?.specify_wallet && rewards?.type == "amounts" ? (
              <Flex
                alignItems={`center`}
                color={"green.500"}
                justifyContent={`space-between`}
              >
                <Text as="p" fontSize="sm" fontWeight={`bold`} mb="0px">
                  {i18n_reward_dashboard("send_reward_to_game_platform")}
                </Text>
                <Text
                  as="p"
                  fontSize="sm"
                  color={rewardTextColor}
                  fontWeight={`bold`}
                  mb="0px"
                >
                  {rewards?.specify_wallet}
                </Text>
              </Flex>
            ) : null}
          </>
        </Flex>
        {status == 1 && (
          <Button
            isDisabled={buttonLoading || fetchingStatusLoading}
            isLoading={buttonLoading || fetchingStatusLoading}
            size="md"
            w="100%"
            variant={`brandPrimary`}
            onClick={() => {
              handleUseReward(member_rewards_uid);
            }}
          >
            {i18n("use_reward")}
          </Button>
        )}
        <Image
          src={`${COMMON_WEB_PATH}/reward_icon/deposit.avif`}
          position="absolute"
          top="-8px"
          right="5px"
          w="58px"
        />
      </Box>
    </>
  );
};

export default ReceiveRewardsDashboard;
